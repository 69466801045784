import gfx from "../../assets/reels/gfx.png"
import vfx from "../../assets/reels/vfx.png"

export const dataReels = [
  {
    "id": 1,
    "title": "Motion Graphics",
    "description": "Project's description",
    "image": gfx,
    "video_link": "https://www.youtube.com/embed/4E8PWZs7Wgc?si=De5464ANTKI6_XDr",
    "type": "GFX"
  },
  {
    "id": 2,
    "title": "Visual Effects",
    "description": "Project's description",
    "image": vfx,
    "video_link": "https://www.youtube.com/embed/F5YMEBHV40Q?si=6PF00evyi6fKD16I",
    "type": "VFX"
  },

]