import React from "react";
import { useState } from "react";
import { dataWork } from "../Components/Data/dataWork.js";
import WorkCard from "../Components/WorkCard.jsx";

function Work() {
  const [selectedType, setSelectedType] = useState("");

  const handleTypeChange = (type) => {
    setSelectedType(type);
    console.log("type", type);
  };

  const renderWorks =
    selectedType === ""
      ? dataWork.map((project, index) => (
          <WorkCard
            title={project.title}
            image={project.image}
            description={project.description}
            key={index}
          />
        ))
      : dataWork
          .filter((filteredProject) => selectedType === filteredProject.type)
          .map((project, index) => (
            <WorkCard
              title={project.title}
              image={project.image}
              description={project.description}
              key={index}
            />
          ));

  return (
    <div className="work">
      <div className="work-page-center">
          <div className="description-section">
            <h2 className="title">Final versions delivered</h2>
            <p className="text">
              Our work ranges from tv shows, films, and documentaries. Here are some of the projects we had fun working on. Contact us to view our reel!
            </p>
          </div>

          <div className="gallery-section">
            <div className="gallery-title">
              <div
                onClick={() => handleTypeChange("")}
                className={selectedType === "" ? "blue-title" : ""}
              >
                All
              </div>
              /
              <div
                onClick={() => handleTypeChange("GFX")}
                className={selectedType === "GFX" ? "blue-title" : ""}
              >
                GFX
              </div>
              /
              <div
                onClick={() => handleTypeChange("VFX")}
                className={selectedType === "VFX" ? "blue-title" : ""}
              >
                VFX
              </div>
              {/* ############# commenting off this section for now  #############
              ################# "type": "Production" for dataWork.js ###########
              /
              <div
                onClick={() => handleTypeChange("Production")}
                className={selectedType === "Production" ? "blue-title" : ""}
              >
                Production
              </div>
              ############ commenting off this section for now  ############# */}
            </div>

            <div className="gallery">{renderWorks}</div>
          </div>
      </div>
    </div>
  );
}

export default Work;
